.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  padding: 0.4rem 0;
  margin: 0 1.6rem;
  height: 100%;
}

.authButton {
  width: 14rem;

  @media (--tablet-viewport) {
    width: 8rem;
    padding: 0.4rem;
  }
}

.button {
  color: white;
  border: none;
  background-color: var(--color-primary--300);
  border-radius: 0.4rem;
  font-weight: var(--font-weight--semi-bold);
  padding: 0 0.8rem;
}

.liveButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.betsContainer {
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.pendingBetsButton {
  font-weight: var(--font-weight--semi-bold);
  background-color: var(--color-primary--400);
  color: var(--white);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5.6rem;
}

.betslipButton {
  font-weight: var(--font-weight--semi-bold);
  background-color: var(--white);
  color: var(--color-secondary--100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5.6rem;

  @media (--tablet-viewport) {
    display: none;
  }
}

.balanceButton {
  font-weight: var(--font-weight--semi-bold);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size--body);
}

.tooltip {
  z-index: var(--z-index--modal);

  & * {
    box-sizing: border-box;
  }
}
