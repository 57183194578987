.innerContainer {
  background: var(--color-grey--50);
  border-radius: 0.4rem;
  padding: 0.8rem 1.6rem;
  margin-bottom: 1.6rem;
  display: flex;
}

.tableRow {
  height: 2rem;
  vertical-align: top;
}

.leftColumn {
  width: 20%;
  min-width: 12rem;
}

.rightColumn {
  font-weight: var(--font-weight--semi-bold);
}

.regularFontWeight {
  font-weight: var(--font-weight--regular);
}
