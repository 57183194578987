.container {
  background: var(--white);
  padding: 0 1.6rem 1.6rem 1.6rem;

  @media (--mobile-viewport) {
    padding: 0 0.8rem 0.8rem 0.8rem;
  }
}

.content {
  background: var(--color-grey--50);
  padding: 1.4rem;
  padding-top: 0;

  @media (--mobile-viewport) {
    padding: 0.8rem;
  }
}

.separator:not(:last-child) {
  border-bottom: 0.1rem solid var(--color-grey--100);
  grid-column: 1 / 5;

  &.shortForm {
    grid-column: 1 / 7;

    &.shortFormBetType {
      grid-column: 1 / 5;

      @media (--tablet-viewport) {
        grid-column: 1 / 3;
      }
    }

    @media (--tablet-viewport) {
      grid-column: 1 / 3;
    }
  }
}

.marginBottom {
  margin-bottom: 1.2rem;
}

.shortFormRunnerInfo {
  grid-column-start: 1;
  grid-column-end: 7;
  background-color: var(--color-grey--50);
  padding: 1.2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.shortFormBetType {
    grid-column-end: 5;

    @media (--tablet-viewport) {
      grid-column-end: 3;
    }
  }

  @media (--tablet-viewport) {
    grid-column-end: 3;
  }
}

.label {
  font-weight: var(--font-weight--semi-bold);
}

.greyLabel {
  color: var(--color-grey--600);
}

.highlightRelevantRace {
  font-weight: var(--font-weight--regular);
  margin-left: auto;
}

.shortFormTabs {
  display: flex;
  justify-content: left;
  gap: 2.4rem;
}

.shortFormTab {
  font-size: var(--font-size--header-1);
  text-decoration: none;
  color: var(--color-grey--400);
  font-weight: var(--font-weight--bold);
  padding: 1.2rem 0;
  position: relative;
  white-space: nowrap;
  width: max-content;

  &.selected {
    color: var(--color-grey--700);
  }

  &.selected::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.4rem;
    background-color: var(--color-grey--700);
  }

  @media (--mobile-viewport) {
    padding: 0 0 1.2rem 0;
  }
}

.minWidth {
  min-width: 100px;

  @media (max-width: 1320px) {
    min-width: unset;
  }
}
