.footer {
  background-color: var(--color-grey--100);
  padding: 0 1.6rem;
  font-size: var(--font-size--label);

  & > *:not(:last-child) {
    border-bottom: 1px solid var(--color-grey--600);
  }

  @media (--tablet-viewport) {
    padding-bottom: 5.4rem;
  }
}

.row {
  padding: 1.6rem 0;
}

.imageList {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -0.8rem;

  & > .imageListItem {
    margin: 0 0.8rem;

    & > a {
      line-height: 0;
      display: block;
    }
  }

  @media (--tablet-viewport) {
    margin: 0;

    & > .imageListItem {
      margin: 0.4rem;
    }
  }
}

.footerLists {
  display: flex;
  flex-direction: row;
  margin: 0 -1.6rem;

  & > .footerList {
    margin: 0 1.6rem;
  }

  @media (--tablet-viewport) {
    flex-direction: column;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.title {
  display: block;
  margin: 0.8rem 0;
  font-weight: var(--font-weight--semi-bold);
}

.link {
  text-decoration: none;
  color: var(--black);
}
