.container {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;

  @media (--tablet-viewport) {
    grid-template-columns: 1fr;
  }
}

.navigationContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.08);
  z-index: var(--z-index--priority);
  background-color: var(--color-grey--50);
  transition: all 0.2s ease-in-out;
}

.contentContainer {
  width: 100%;
  overflow: hidden;
}

.content {
  position: relative;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5.6rem;
  background-color: #319b34;
  display: flex;
  justify-content: flex-end;
  z-index: var(--z-index--layout);
}

.betslipContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 29.6rem;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.08);
  background-color: var(--color-grey--50);
  z-index: var(--z-index--priority);

  &.embedded {
    position: absolute;
  }

  @media (--tablet-viewport) {
    display: none;
    width: 100%;
    background-color: var(--color-grey--50);

    &.open {
      display: block;
    }
  }
}
