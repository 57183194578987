.container {
  background: var(--white);
  padding: 0 1.6rem 1.6rem 1.6rem;
  line-height: var(--line-height--small);
  font-size: var(--font-size--label);
}

.label {
  font-weight: var(--font-weight--semi-bold);
}

.runnerContainer {
  display: grid;
  grid-template-columns: 12rem 1fr;
  gap: 0 0.8rem;
  padding: 1.4rem 4rem;
  background: var(--color-grey--50);
}

.long {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
