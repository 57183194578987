.container {
  padding: 1.6rem;
  background-color: var(--white);
  border-radius: 0.4rem;
}

.title {
  font-size: var(--font-size--header-1);
  font-weight: var(--font-weight--bold);
}

.subText {
  color: var(--color-grey--400);
  font-size: var(--font-size--label);
}
