.toggleSwitchContainer {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: var(--color-grey--50);
  border-radius: 0.8rem;
  color: var(--white);
}

.toggleSwitchLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  font-weight: var(--font-weight--semi-bold);
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.toggleSwitchToggle {
  position: relative;
  background: var(--color-grey--200);
  width: 4.8rem;
  height: 2.4rem;
  display: block;
  border-radius: 2.4rem;
  transition: all 0.2s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    left: 0.2rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--white);
  }
}

.toggleSwitchInput {
  display: none;

  &:checked + .toggleSwitchToggle {
    background-color: var(--color-secondary--200);

    &::before {
      left: auto;
      right: 0.2rem;
    }
  }
}
