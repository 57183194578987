.container {
  padding: 1.6rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
}

.filters {
  display: flex;
  flex-flow: row wrap;
  gap: 0.8rem;
}

.section {
  margin: 1.6rem 0;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: var(--color-grey--600);
  font-size: var(--font-size--label);
  font-weight: var(--font-weight--bold);
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
  gap: 0.8rem;
  line-height: var(--line-height--small);
  font-size: var(--font-size--label);

  & > .tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0.4rem;
  }

  @media (--tablet-viewport) {
    margin-top: 0.4rem;
  }

  @media (--mobile-viewport) {
    display: none;
  }
}
