.container {
  width: 100%;
  height: 100%;
  background-color: var(--color-grey--50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 2.4rem 4rem 4rem;

  @media (--mobile-viewport) {
    padding: 0;
    border-radius: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.success {
  background: linear-gradient(296.82deg, #3db53a 0%, #17858e 100%);
}

.closeButton {
  color: var(--black);
}

.formContainer {
  padding: 4rem;
  background-color: var(--white);
  border-radius: 0.8rem;
  width: 60rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);

  @media (--tablet-viewport) {
    width: 100%;
    max-width: 60rem;
  }

  @media (--mobile-viewport) {
    border-radius: 0;
  }
}

.header {
  text-align: center;
}

.subHeading {
  font-size: var(--font-size--header-3);
  margin-bottom: 3rem;
}

.secondary {
  margin-top: 2.4rem;
}

.select {
  background-color: var(--color-grey--50);
}

.subText {
  font-size: var(--font-size--caption);
  color: var(--color-grey--600);
  line-height: var(--line-height--title);
}

.button {
  width: 100%;
}

.doubleColumn {
  display: flex;
  gap: 0.7rem;
  justify-content: space-between;
}

.textLink {
  color: var(--color-secondary--200);
  font-weight: var(--font-weight--semi-bold);
  cursor: pointer;
  text-decoration: none;
}
