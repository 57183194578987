.content {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
}

.dateInputs {
  display: flex;
  & div {
    margin-right: 0.8rem;
  }
}
.dateInput {
  background-color: var(--white);
}

.headerContent {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.8rem;
}

.filterContent {
  border-radius: 0.4rem;
  border: 0.1rem solid;
  border-color: var(--color-grey--100);
  padding: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.buttonTitle {
    margin-left: 0.4rem;
}

.filterButton {
  margin-left: auto;
}

.downloadIcon {
  margin-left: 1.6rem;
}