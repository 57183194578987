.container {
  margin: 0 1.6rem;
  padding: 1.6rem 1.6rem 0;
  background-color: var(--white);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;

  @media (--mobile-viewport) {
    margin: 0;
  }
}

.breadcrumbs {
  margin: 0 0 0.8rem 0;
  padding: 0;
  list-style: none;
}

.breadcrumb {
  display: inline-block;
  font-size: var(--font-size--caption);

  &:not(:last-child)::after {
    content: '>';
    font-weight: var(--font-weight--bold);
    margin: 0 0.4rem;
  }
}

.breadcrumbLink {
  text-decoration: none;
  color: var(--black);
}

.headerComponent {
  padding-bottom: 1.6rem;
}

.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 -0.6rem;
}

.tab {
  font-size: var(--font-size--body);
  text-decoration: none;
  color: var(--color-grey--600);
  font-weight: var(--font-weight--bold);
  padding: 0.8rem 0.6rem;
  position: relative;
  white-space: nowrap;
  margin: 0 0.4rem;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;

  &:focus:not(:disabled) {
    box-shadow: none !important;
    background-color: #fafafa;
  }

  &.selected::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.4rem;
    background-color: var(--color-secondary--200);
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }

  &.mobileOnly {
    display: none;

    @media (--tablet-viewport) {
      display: block;
    }
  }

  &.desktopOnly {
    display: block;

    @media (--tablet-viewport) {
      display: none;
    }
  }
}
