.container {
  padding: 1.6rem 1.6rem 0;
  margin-bottom: 0.8rem;
  overflow: hidden;
}

.singleRow {
  display: flex;
  gap: 0.8rem;
}

.existingAccount {
  display: flex;
  background: var(--white);
  margin-top: 1.6rem;
}

.select {
  padding-left: 1.6rem;
  background-color: var(--color-grey--50);
  height: 4rem;
  border: 0 !important;
}

.input {
  height: 4rem;
}

.button {
  display: flex;
  background: var(--color-grey--50);
  width: 4rem;
  align-items: center;
  align-self: center;
  margin-top: 0.2rem;
  margin-left: 0.8rem;
}

.addAccountButton {
  height: 4rem;
  align-self: center;
  margin-top: 0.2rem;
  margin-left: 0.8rem;
  white-space: pre;
  border-color: var(--color-grey--200);
  color: var(--black);
}

.cardImage {
  margin-bottom: -0.4rem;
}
