.container {
  margin: 0.8rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.race {
  text-decoration: none;
  color: var(--black);

  &:hover {
    cursor: pointer;
  }
}

.desktop {
  width: 100%;

  & .grid {
    display: grid;
    grid-gap: 1px;
    background-color: var(--color-grey--100);
    border-radius: 0.4rem;

    & > * {
      background-color: var(--color-grey--50);
      min-height: 6.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0.8rem;
    }
  }

  & .header {
    background-color: var(--color-secondary--300);
    min-height: auto;
    color: var(--white);
    padding: 0.8rem 0;
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--label);
    width: 100%;
  }

  & .track {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: var(--font-size--label);
    background-color: var(--white);
    width: 12rem;

    & > span {
      display: block;
    }

    & > .name {
      font-weight: var(--font-weight--bold);
    }

    & > .country {
      color: var(--color-grey--600);
    }
  }

  & .race {
    font-size: var(--font-size--label);
    position: relative;
    width: 100%;

    &.open {
      background-color: var(--white);

      &:hover {
        background-color: var(--color-grey-50);
      }
    }
  }

  & .badges {
    position: absolute;
    left: 0.4rem;
    bottom: 0.4rem;
    line-height: 0;
  }

  @media (--tablet-viewport) {
    display: none;
  }
}

.mobile {
  display: none;

  & .list {
    margin: 0 -1.6rem;
  }

  & .meeting {
    background-color: var(--white);
    padding: 1.6rem;
    margin-bottom: 0.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border: none;
    text-align: left;
    appearance: none;

    &:hover {
      cursor: pointer;
    }
  }

  & .track {
    font-size: var(--font-size--label);
    flex: 1;

    & > .name {
      font-weight: var(--font-weight--bold);
    }

    & > .country {
      color: var(--color-grey--600);
    }

    &:hover {
      background-color: var(--color-grey-50);
    }
  }

  & .secondary {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--label);
  }

  & .badges {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 -0.2rem;

    & > * {
      margin: 0 0.2rem;
    }
  }

  & .lozenge {
    margin-left: 0.4rem;
    background-color: var(--color-grey--50);
    border-radius: 0.4rem;
    padding: 0 0.4rem;

    & > .raceNumber {
      font-weight: var(--font-weight--bold);
      margin-right: 0.4rem;
    }
  }

  & .race {
    padding: 0.4rem 1.6rem;
    background-color: var(--white);
    opacity: 0.8;
    margin-bottom: 0.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & .circle {
      width: 3.2rem;
      height: 3.2rem;
      background-color: var(--color-grey--100);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-weight: var(--font-weight--bold);
    }

    & .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0 0.4rem;
    }

    & .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    & .trackName {
      flex: 1;
      font-weight: var(--font-weight--bold);
      display: block;
      font-size: var(--font-size--label);
    }

    & .prizeMoney {
      flex: 1;
      display: block;
      color: var(--color-grey--600);
      font-size: var(--font-size--label);
    }

    & .secondary {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  @media (--tablet-viewport) {
    display: block;
  }
}

.noMeetingsAvailable {
  display: block;
  margin: 0.4rem 0;
  font-size: var(--font-size--label);
}
