.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-grey--50);
  font-size: var(--font-size--label);
  position: relative;
  top: -1.6rem;
  height: 0;
  user-select: none;
  gap: 0.4rem;
  padding-left: 0.8rem;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    font-weight: var(--font-weight--bold);
  }
}

.hideLabelOnMobile {
  @media (--tablet-viewport) {
    display: none;
  }
}

.button {
  background-color: var(--white);
  border-radius: 0.4rem;
  border: none;
  height: 100%;
  padding: 1.2rem;
  min-width: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    background-color: var(--color-secondary--200);
    color: var(--white);
  }
}

.actions {
  padding: 0 0.8rem;
}
