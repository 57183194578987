.container {
  position: relative;
}

.scrollContainer {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--white);
    border-radius: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey--100);
    border-radius: 0.8rem;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--white);
  border-radius: 50%;
  border: none;
  color: var(--black);
  line-height: 0;
  box-shadow: 0 6px 12px 1px rgba(0, 0, 0, 0.12);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  width: 3.2rem;
  height: 3.2rem;
  
  &.visible {
    visibility: visible;
    opacity: 0.3;
  }

  &.left {
    left: 0.8rem;
  }

  &.right {
    right: 0.8rem;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.containerShortForm {
  grid-column-start: 1;
  grid-column-end: 7;
  background-color: var(--color-grey--50);

  @media (--tablet-viewport) {
    grid-column-end: 3;
  }
}

.scrollContainerShortForm {
  justify-content: center;
}

.containerShortFormBetType {
  grid-column-start: 1;
  grid-column-end: 5;
  background-color: var(--color-grey--50);

  @media (--tablet-viewport) {
    grid-column-end: 3;
  }
}
