.container {
  margin-bottom: 1.6rem;
  width: 100%;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.4rem;
}

.label {
  display: block;
  color: var(--color-grey--600);
  white-space: nowrap;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}

.errorText {
  display: block;
  font-size: var(--font-size--caption);
  color: var(--color-error);
  margin-top: 0.4rem;
}

.hintText {
  display: block;
  font-size: var(--font-size--caption);
  color: var(--color-grey--300);
  margin-top: 0.4rem;
}

.hasError {
  & * {
    border-color: var(--color-error);
  }
}

.iconContainer {
  display: inline-flex;
  cursor: pointer;
  margin-left: 0.4rem;
  border: 0;
  background: transparent;
  padding: 0;
}

.icon {
  width: 1.2rem;
  height: 1.2rem;
  color: var(--color-grey--400);
}

.tooltip {
  background: var(--color-secondary--200);
  color: var(--white);
  font-family: var(--font-family);
  font-size: var(--font-size--label);
  border-radius: 0.4rem;
  z-index: var(--z-index--badge);
  text-align: center;
  max-width: 18rem;
  padding: 0.8rem;
  line-height: 1.6rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.arrow,
.arrow::before {
  position: absolute;
  left: 0;
  width: 0.8rem;
  height: 0.8rem;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.tooltip[data-popper-placement^='top'] > .arrow {
  bottom: -0.4rem;
}
.tooltip[data-popper-placement^='bottom'] > .arrow {
  top: -0.4rem;
}
.tooltip[data-popper-placement^='left'] > .arrow {
  right: -0.4rem;
}
.tooltip[data-popper-placement^='right'] > .arrow {
  left: -0.4rem;
}
