.container {
  background-color: var(--color-grey--100);
  padding: 0 1.6rem;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--black);
  padding: 0.8rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  border-radius: 0.4rem;
  margin: 1.6rem 0;
  scroll-snap-align: start;
  min-width: 12rem;

  &:not(:last-child) {
    margin-right: 0.8rem;
  }
}

.icon {
  margin-bottom: 0.6rem;
}

.label {
  font-size: var(--font-size--label);
  font-weight: var(--font-weight--semi-bold);
}
