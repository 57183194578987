.container {
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  background: #1c4b53;
  color: white;
  box-shadow: none;
}

.selections {
  padding: 0.8em 0.8em 0 0.8em;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.8em;
  padding: 0.8em;
}

.icon {
  font-size: 0.8em;
  margin-right: 0.8em;
}

.slideupMenu {
  width: 100%;
  min-height: 3em;
  display: flex;
  flex-direction: row;
  background: var(--color-secondary--200);
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0.8em;
  font-size: 1.2em;
  box-shadow: 0 0 1px 0 var(--color-secondary--200) !important;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  border: none;
  cursor: pointer;
}

.chevron {
  margin-left: 0.8rem;

  &.selected {
    transform: rotate(180deg);
  }
}

.label {
  display: block;
  font-size: var(--font-size--body);
}

.input {
  padding: 0.7em 0.8em;
  width: 10em;
}

.clearButton {
  white-space: nowrap;
}

.addButton {
  white-space: nowrap;
}

.perComboLabel {
  margin-bottom: 0.4rem;
}

.totalCostLabel {
  margin-bottom: 0.4rem;
}

.multiActionContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 1.6rem;
  gap: 0.8rem;
}

.rightContainer {
  display: flex;
  align-items: flex-end;
  gap: 1.6rem;
}

.selectionActionContainer {
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem;
  gap: 0.8rem;
}

.selectionText {
  font-size: 1.4rem;
}

.combos {
  font-size: 1.2rem;
}
