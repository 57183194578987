.calcContainer {
  position: absolute;
  bottom: 9rem;
  left: 0;
  width: 100%;
  background: var(--color-grey--800);
  color: white;
}

.numpadCalc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1rem;

  & > * {
    width: calc((100% / 4) - 0.8rem);
    margin: 0.4rem;
  }
}

.buttonCalc {
  font-size: var(--font-size--header-1);
  background: rgba(255, 255, 255, 0.147154);
  border-radius: 0.4rem;
  height: 4rem;
  color: white;
  border: rgba(255, 255, 255, 0.147154);
}

.buttonBet {
  height: 4rem;
}

.numpadHeader {
  background: var(--color-grey--800);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: left;
  color: white;
  padding: 0.8rem 0.5rem 0 1.5rem;
}
