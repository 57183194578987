.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: var(--font-size--header-2);
  font-weight: var(--font-weight--semi-bold);
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey--100);
  height: 8.6rem;
  width: 8.6rem;
  border-radius: 50%;
}

.icon {
  height: 80%;
  width: 80%;
  color: var(--color-grey--300);
}

.primaryText {
  color: var(--color-grey--600);
  margin-top: 1.5rem;
}

.secondaryText {
  color: var(--color-grey--400);
  margin-top: 0.8rem;
}
