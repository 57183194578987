.container {
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  overflow: hidden;
  border: 1px solid var(--color-grey--100);
  align-self: flex-start;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  max-width: 10.8rem;

  @media (--tablet-viewport) {
    margin-top: 0.8rem;
  }

}
 
.select {
  padding: 0.8rem 0.4rem;
  border-radius: 0;
  border: none;
  background: none;
  color: var(--color--secondary--200);
  background-color: var(--white);
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.selectText {
  font-weight: bold;
}
