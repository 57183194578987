.empty {
  background-color: var(--white);
  height: 6.9rem;
}

.separator {
  grid-column: 1 / 3;
  height: 0.2rem;
  background-color: var(--color-grey--50);
}
