.container {
  background-color: var(--white);
  padding: 2.4rem 4rem 4rem;
  border-radius: 0.4rem;
  overflow: auto;

  @media (--mobile-viewport) {
    padding: 2.4rem 1.6rem;
    border-radius: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.header {
  text-align: center;
}

.title {
  display: block;
  font-weight: var(--font-weight--bold);
}

.description {
  color: var(--color-grey--500);
}
