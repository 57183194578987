.container {
  padding: 1.6rem;
}

.row {
  width: 50rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
}

.heading {
  font-weight: var(--font-weight--semi-bold);
  font-size: var(--font-size--display-1);
  margin-top: 2.4rem;
}

.seperator {
  margin: 1.6rem 0 1.6rem 0;
  border-bottom: 0.1rem solid var(--color-grey--100);
}