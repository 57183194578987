.checkboxWrapper {
  display: block;
}

.checkbox {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.4rem;
  border: 0.2rem solid var(--color-grey--400);
  background-color: var(--white);
  transition: all 0.2s ease;
}

.icon {
  transition: opacity 0.2s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 2rem;
  height: 2rem;
}

.input {
  position: absolute;
  border: none;
  width: 0;
  height: 0;
  outline: 0;
  appearance: none;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;

  &:checked {
    & + .checkbox {
      background-color: var(--color-secondary--300);
      border-color: var(--color-secondary--400);
    }
  }

  &:disabled {
    & + .checkbox {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &:not(:disabled):hover {
    & + .checkbox {
      cursor: pointer;
    }
  }
}
