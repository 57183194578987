.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-secondary--200);
  color: var(--white);
  height: 4rem;
  padding: 0 2rem;
  margin-bottom: 1.4rem;
  border: 0.1rem solid var(--color-grey--100);
}

.table {
  border-collapse: collapse;
  margin-bottom: 2rem;
}

.with {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--font-weight--regular);
}

.gridHeader {
  background: var(--color-grey--50);
  padding: 0.8rem 0 1.4rem;
}

.marginLeft {
  margin-left: 0.6rem;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
}

.topHeaderContent {
  position: relative;
}

.leftHeaders {
  padding: 0.9rem 1.3rem;
  background: var(--color-grey--50);
}

.leftHeaderContent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 3.2rem;
  max-width: 3.2rem;
  margin-right: 0.5rem;
}

.numBox {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--caption);
  color: var(--black);
  background: var(--white);
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 4px;
  overflow: hidden;
  cursor: default;
}

.topNumBox {
  top: 2.4rem;
  left: 3.8rem;
}

.leftNumBox {
  top: 1.9rem;
  left: 1.9rem;
}

.activeCell {
  background: var(--color-grey--50);

  &:hover {
    background: var(--color-secondary--200);
    color: var(--white);
    cursor: pointer;
  }
}

.tableCell {
  padding: 1rem;
  width: 6rem;
  margin: 0.4rem;
  border-radius: 0.4rem;
}
