.tabsGrid {
  border-radius: 0.4rem;
  margin: 1.6rem 0;

  @media (--tablet-viewport) {
    grid-template-columns: 1fr auto;
  }
}

.tab {
  margin-right: 0.8rem;

  &.selected {
    background-color: var(--color-grey--600);
    color: var(--white);
    border-color: var(--color-grey--600);
  }
}
