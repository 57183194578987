.container {
  background-color: black;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.6rem;
}

.topBar {
  background-color: var(--black);
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.icon path {
  stroke: var(--white);
  fill: var(--white);
}

.closeIconContainer {
  display: flex;
  margin: 0;
  border: none;
  background: none;
  padding: 1rem;

  &:hover {
    cursor: pointer;

    & .icon path {
      stroke: var(--color-grey--600);
      fill: var(--color-grey--600);
    }
  }

  &:active:focus {
    outline-style: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
  }
}
