.container {
  padding-bottom: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-secondary--200);
  color: var(--white);
  height: 4rem;
  padding: 0 2rem;
  margin-bottom: 1.4rem;
  border: 0.1rem solid var(--color-grey--100);
}

.competitors {
  display: flex;
  align-items: center;
  justify-content: center;
}

.competitor {
  width: 11rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size--label);
  text-overflow: ellipsis;
  padding: 0.5rem 0.1rem;
}

.competitorName {
  max-height: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 9rem;
}

.psRows {
  width: 100%;
}

.bg {
  padding: 0.8rem 0 0.6rem;
}

.row {
  display: flex;
}

.rowHeading {
  width: 8rem;
  max-width: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: var(--font-weight--bold);
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #23262b;
}

.value {
  width: 11rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.1rem;
}

.oddsButtonContainer {
  width: 11rem;
}

.oddsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-grey--50);
  height: 4rem;
  padding: 1.2rem;
  border-radius: 0.4rem;
  min-width: 4.8rem;

  &:hover {
    cursor: pointer;
    background-color: var(--color-secondary--200);
    color: var(--white);
  }
}

.selected {
  background-color: var(--color-secondary--200);
  color: var(--white);
}

.noOdds {
  width: 11rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
