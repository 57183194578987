.section {
  grid-column: 1 / 3;
  background-color: var(--color-grey--50);
}

.grid {
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: var(--white);
  border-radius: 0.4rem;
  margin: 4rem 0 1.6rem;

  & > .separator {
    grid-column: 1 / 3;
    height: 0.2rem;
    background-color: var(--color-grey--50);
  }

  &.Win {
    grid-template-columns: 1fr repeat(3, auto);

    & > .separator {
      grid-column: 1 / 5;
    }
  
    & > .section {
      grid-column: 1 / 5;
    }

    @media (--tablet-viewport) {
      grid-template-columns: 1fr auto;

      & > .section {
        grid-column: 1 / 3;
      }

      & > .separator {
        grid-column: 1 / 3;
      }
    }
  }
}

