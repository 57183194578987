.withdrawMenu {
  background: var(--white);
  border-radius: 0.4rem 0 0 0.4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  min-width: 25rem;
  width: 36%;
  font-weight: var(--font-weight--semi-bold);
  white-space: nowrap;

  @media (max-width: 720px) {
    width: 100%;
    border-radius: 0.4rem 0.4rem 0 0;
  }

  @media (min-width: 1010px) and (max-width: 1200px) {
    width: 100%;
    border-radius: 0.4rem 0.4rem 0 0;
  }
}

.menuItemContainer {
  border-bottom: 1px solid var(--color-grey--100);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 1.6rem 1.6rem 1.2rem;
  cursor: pointer;
  border-left: 4px solid var(--white);

  & div {
    align-items: center;
  }

  &:first-of-type {
    border-top-left-radius: 0.4rem;
  }

  &:hover {
    border-left: 4px solid var(--color-primary--50);
  }

  &.selected {
    border-left: 4px solid var(--color-primary--100);
  }
}

.flex {
  display: flex;
}

.rightMargin {
  margin-right: 0.8rem;
}
