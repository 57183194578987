.cardContent {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 0;
}

.description {
  display: flex;
  flex-direction: column;
}

.heading {
  font-weight: var(--font-weight--semi-bold);
  margin-bottom: 1.6rem;
}

.card {
  padding: 0 1.6rem;
  margin: 1rem 0;
}

.select {
  max-width: 26rem;
  border: 1px solid var(--color-grey--100);
}

.seperator {
  border-bottom: 0.1rem solid var(--color-grey--100);
}

.bottomContent {
  padding: 1.6rem 0;
}

.bottomHeading {
  font-weight: var(--font-weight--semi-bold);
}
.disabled {
  display: none;
}

.stakeButtons {
  margin: 0 0 0.8rem 0;
}

.button {
  border: 1px solid var(--color-grey--100);
  margin: 0.4rem 0.4rem 0 0;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  font-weight: var(--font-weight--normal);

  &.selected {
    background-color: var(--color-secondary--200);
    color: var(--white);
  }
}

.bottomContentActions {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 46.5rem;
  margin: 2.6rem 0 0 0;
  & div {
    margin-bottom: 0.4rem;
  }
}

.actionButtons {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 1fr 1fr;
}