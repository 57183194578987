.container {
  width: 100%;
  background-color: var(--color-grey--50);
  padding: 0.8rem 0;
  border-top: 0.1rem solid var(--color-grey--100);
  border-bottom: 0.1rem solid var(--color-grey--100);
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 0.4rem;
  margin: 0 0.8rem;
}

.sort {
  display: flex;
  justify-content: space-between;
  height: 3rem;
  padding: 1rem;
}

.sortButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-grey--50);
  position: relative;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    font-weight: var(--font-weight--bold);
  }
}

.content {
  max-height: 30rem;
  background-color: var(--white);
  overflow-y: auto;

  & > *:not(:last-child) {
    border-bottom: 0.1rem solid var(--color-grey--100);
  }
}
