.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  line-height: var(--line-height--medium);
  padding: 0.4rem 1.2rem;

  &.center {
    justify-self: center;
  }
}

.icon {
  align-self: center;
}

.raceDetails {
  flex: 1;
  margin: 0 0.8rem;
  overflow: hidden;
}

.raceName {
  display: block;
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--body);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  color: var(--black);
}

.pool {
  display: block;
  font-size: var(--font-size--label);
  color: var(--color-grey--600);
}

.countdown {
  font-size: var(--font-size--label);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.separator {
  border-bottom: 0.1rem solid var(--color-grey--100);
  grid-column: 1 / 3;
  margin: 0.4rem 0;
  background-color: var(--white);
}

.racePool {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 0.4rem;
}

.icons {
  flex: 1;
}

.link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
