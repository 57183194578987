.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.select {
  font-size: var(--font-size--display-1);
  font-weight: var(--font-weight--bold);
  background-color: transparent;
  border: none;
  margin-left: -0.2rem;

  &:hover {
    cursor: pointer;
  }
}

.container {
  margin: 0 1.6rem;
  padding: 1.6rem 1.6rem 0;
  background-color: var(--white);
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}

.buttonLabel {
  margin-left: 0.4rem;

  @media (--tablet-viewport) {
    display: none;
  }
}

.actionButton {
  height: 3.2rem;
  min-width: 5rem;

  @media (--tablet-viewport) {
    padding: 0;
  }
}

.actionButtonIcon {
  margin: 0;
  padding: 0;
}

.raceNumbers {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  padding: 0.8rem 0;
}

.raceNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  font-size: var(--font-size--body);
  text-decoration: none;
  color: var(--black);
  background-color: var(--color-grey--100);
  border-radius: 0.4rem;
  transition: all 0.2s ease-in-out;
  border: 0.2rem solid transparent;
  position: relative;

  &.inMultiExotic {
    border-color: var(--color-secondary--100);
  }

  &.selected {
    background-color: var(--color-grey--600);
    border-color: var(--color-grey--600);
    color: var(--white);
  }

  &.closed {
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-grey--600);
    color: var(--white);
  }
}

.descriptionButton {
  font-size: var(--font-size--label);
  color: var(--black);
  font-weight: var(--font-weight--bold);
  line-height: 1.2;
}

.description {
  margin-top: 0.8rem;
  font-size: var(--font-size--label);
  font-weight: var(--font-weight--bold);
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > :first-child {
    flex: 1;
  }
}

.countdown {
  border-radius: 0.2rem;
  background-color: var(--color-grey--100);
  padding: 0 0.4rem;
  margin-left: 0.4rem;
}

.details {
  font-size: var(--font-size--label);

  & > *:not(:last-child)::after {
    content: '|';
    padding: 0 0.4rem;
    color: var(--color-grey--100);
  }
}

.label {
  font-weight: var(--font-weight--bold);
}

.raceDescription {
  font-size: var(--font-size--label);
  margin-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.chevron {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.8rem;
  transition: transform 0.5s ease-out;

  &.selected {
    transform: rotate(180deg);
    transition: transform 0.5s ease-out;
  }
}

.checkmarkWrapper {
  position: absolute;
  border-radius: 50%;
  background-color: var(--color-success);
  top: -0.8rem;
  right: -0.4rem;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid var(--white);
}

.checkmark {
  width: 1rem;
  height: 1rem;
}
