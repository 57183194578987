.button {
  position: relative;
  background-color: var(--color-grey--50);
  border-radius: 0.4rem;
  font-size: var(--font-size--body);
  border: none;
  padding: 1.2rem;
  width: 100%;
  min-width: 4.8rem;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    background-color: var(--color-secondary--200);
    color: var(--white);
  }
}

.drift {
  color: #049b5e;
}

.firm {
  color: #e2215a;
}

.drift::before {
  content: '';
  position: absolute;

  /* width: 1rem;
  height: 10px; */
  bottom: -0.3rem;
  right: 2.5rem;

  /* background: #049B5E; */
  transform: rotate(90deg);
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-right: 0.8rem solid #049b5e;
  animation: firm-animation 1.5s;
}

.firm::before {
  content: '';
  position: absolute;

  /* width: 1rem;
  height: 10px; */
  top: -0.3rem;
  right: 2.5rem;

  /* background: #049B5E; */
  transform: rotate(-90deg);
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-right: 0.8rem solid #e2215a;
  animation: drift-animation 1.5s;
}

@keyframes firm-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes drift-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
