.container {
  margin-bottom: 1.6rem;
}

.depositInputRow {
  display: flex;
  gap: 0.8rem;
}

.amountButtonContainer {
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
}

.button {
  padding: 1rem;
  width: 100%;
  border-radius: 0.4rem;
  border: 1px solid var(--color-grey--200);
  display: flex;
  color: var(--black);

  &:hover {
    border: 1px solid var(--color-grey--600);
  }
}

.buttonContainer {
  margin-top: 1.6rem;
  display: flex;
  gap: 0.8rem;
}

.submitButton {
  width: 100%;
  height: 4rem;
}

.securityNoInput {
  height: 4rem;
}
