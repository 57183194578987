.bm-root {
  min-height: 100vh;
  font-family: var(--font-family);
  line-height: var(--line-height--medium);
  font-weight: var(--font-weight--regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
  background: var(--color-grey--50);

  & * {
    box-sizing: border-box;
  }

  & button {
    cursor: pointer;
  }

  & .link {
    text-decoration: none;
    color: var(--color-secondary--200);
  }

  & input,
  & textarea,
  & select,
  & button,
  & a {
    font-family: var(--font-family);

    &:disabled {
      cursor: not-allowed;
    }

    &:focus:not(:disabled) {
      box-shadow: 0 0 1px 2px var(--color-grey--50);
      outline: none;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    margin: 0;
  }

  & h1 {
    font-size: var(--font-size--display-1);
  }

  & h2 {
    font-size: var(--font-size--header-1);
  }

  & *::-webkit-scrollbar {
    display: none;
  }
}
