.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 0.8rem;
  margin: -0.4rem;

  & > * {
    width: calc((100% / 4) - 0.8rem);
    margin: 0.4rem;
  }
}

.button {
  padding: 0.8rem;
  font-size: var(--font-size--caption);
  background-color: var(--color-grey--50);

  &:hover {
    background-color: var(--color-secondary--200);
    color: var(--white);
  }
}
