.header {
  display: flex;
  flex-flow: row wrap;
  gap: 1.6rem;

  @media (--mobile-viewport) {
    flex-direction: column;
    margin: 0 -1.6rem;
  }
}

.card {
  flex: 1;
  padding: 1.6rem 2.4rem;
  max-width: 100%;

  @media (--mobile-viewport) {
    border-radius: 0;
  }
}

.accountContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  & .title {
    margin-bottom: 1.6rem;
  }

  & p {
    margin: 0 0 0.8rem;
  }

  & .label {
    color: var(--color-grey--600);
    font-size: var(--font-size--caption);
    margin-right: 1.6rem;
  }
}

.balanceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  height: 100%;

  & .label,
  & .value {
    font-weight: var(--font-weight--semi-bold);
    display: flex;
    align-items: center;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1.6rem 4rem;
}

.navigationContainer {
  margin: 1.6rem 0;
}

.navigation {
  display: flex;
  flex-direction: column;

  @media (--mobile-viewport) {
    margin: 0 -1.6rem;
  }
  
  & > * {
    &:not(:last-child) {
      border-bottom: 0.1rem solid var(--color-grey--100);
    }
  }
}

.link {
  flex: 1;
  padding: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: inherit;
  font-weight: var(--font-weight--semi-bold);
}
