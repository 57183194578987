.shortFormPositionInfo {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 7;
  background: var(--color-grey--100);
  padding: 1.2rem;
  margin-top: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.4rem;
  border: none;

  &.shortFormBetType {
    grid-column-end: 5;
  }

  @media (--tablet-viewport) {
    grid-column-end: 3;
  }

  @media (--mobile-viewport) {
    padding: 0.4rem;
  }
}

.icon {
  margin-right: 1.6rem;

  @media (--mobile-viewport) {
    display: none;
  }
}

.label {
  font-weight: var(--font-weight--semi-bold);
  margin-right: 0.6rem;
}

.separator:not(:last-child) {
  border-bottom: 0.1rem solid var(--color-grey--100);
  grid-column: 1 / 5;
  margin: 0 0.8rem;

  &.shortForm {
    grid-column: 1 / 7;

    &.shortFormBetType {
      grid-column: 1 / 5;
    }

    @media (--tablet-viewport) {
      grid-column: 1 / 3;
    }
  }

  @media (--tablet-viewport) {
    grid-column: 1 / 3;
  }
}

.chevronButton {
  background: none;
  border: none;
  display: flex;
  transition: transform 0.5s ease;

  &:hover {
    cursor: pointer;
  }

  &.expanded {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
}

.minWidth {
  display: flex;
  align-items: center;
  min-width: 94px;

  @media (max-width: 1320px) {
    min-width: unset;
  }
}
