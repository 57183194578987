.competitor {
  display: flex;
  flex-direction: row;
  padding: 0.8rem 1.6rem;

  & > *:not(:last-child) {
    margin-right: 0.8rem;
  }

  @media (--mobile-viewport) {
    padding: 0.8rem;
  }
}

.competitorDetails {
  display: flex;
  flex-direction: column;
  line-height: var(--line-height--small);
  font-size: var(--font-size--label);
}

.competitorName {
  font-size: var(--font-size--body);
  font-weight: var(--font-weight--semi-bold);
  line-height: var(--line-height--large);
}

.scratched {
  opacity: 0.6;

  & .competitorName {
    text-decoration: line-through;
  }
}

.silkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.8rem;
}

.positionBox {
  margin-top: 0.4rem;
  width: 2.6rem;
  height: 1.6rem;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  line-height: 1.6rem;
  font-style: normal;
  font-weight: bold;
  text-align: center;
}

.position--1 {
  background: #f6ddad;
}

.position--2 {
  background: #e0e2f2;
}

.position--3 {
  background: #fec19f;
}

.position--4 {
  background: #fff;
  border: 0.1rem solid #bec3cd;
}

.runnerContainer {
  display: grid;
  grid-template-columns: 12rem auto;
  gap: 0 0.8rem;
  color: var(--color-grey--600);
}

.long {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
