.sort {
  display: flex;
  margin-top: 1.6rem;
  & span {
    margin-right: 1.6rem;
  }
}

.filtersSelect {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.8rem;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-grey--50);
  font-size: var(--font-size--label);
  position: relative;
  user-select: none;
  gap: 0.4rem;
  padding-left: 0.8rem;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    font-weight: var(--font-weight--bold);
  }
}