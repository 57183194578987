.row {
  display: flex;
  flex-flow: row wrap;
  gap: 1.6rem;

  @media (--mobile-viewport) {
    margin: 0 -1.6rem;
  }

  & > :first-child {
    flex: 2;
  }

  & > :last-child {
    flex: 1;
    min-width: 32rem;
  }
}

.card {
  padding: 0 1.6rem;
}

.detail {
  display: grid;
  grid-template-columns: 12rem 1fr auto;
  align-items: center;
  padding: 1.6rem 0;

  &:not(:last-child) {
    border-bottom: 0.1rem solid var(--color-grey--100);
  }
}

.label {
  font-weight: var(--font-weight--bold);
}

.secretDetail {
  color: inherit;
  text-decoration: none;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 1.6rem 0;

  &:not(:last-child) {
    border-bottom: 0.1rem solid var(--color-grey--100);
  }
}

.form {
  background-color: var(--white);
  border-radius: 0.8rem;
  margin-top: 1.6rem;
  width: 100%;
}

.link {
  color: inherit;
}
