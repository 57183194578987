.withdrawContainer {
  border-radius: 0.4rem 0 0 0.4rem;
  display: flex;
  max-width: 100rem;

  @media (max-width: 720px) {
    flex-direction: column;
  }
}

.contentSection {
  background: var(--color-grey--100);
  font-size: var(--font-size--label);
  width: 100%;
  padding: 1.6rem;
}

.balanceTotals {
  font-weight: var(--font-weight--semi-bold);
  display: flex;
  gap: 2.4rem;
  margin-bottom: 1.6rem;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid var(--color-grey--100);
}

.balanceItem {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
