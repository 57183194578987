.container {
  padding: 1.6rem;
  position: relative;
}

.headerContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 0.8rem;
}

.buttons {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}

.secondaryButtons {
  margin-left: auto;
}

.toggleButton {
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.builder {
  position: relative;
  bottom: 0;
  z-index: var(--z-index--badge);

  &.fixed {
    position: fixed;
  }
}

.betTypeButton {
  border-radius: 0.4rem;
  border: 0.2rem solid var(--color-grey--600);
  background: var(--white);
}

.selected {
  background: var(--color-grey--600);
  color: var(--white);
}

.raceCardContainer {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  width: 100%;
}

.raceCard {
  flex: 1;
}

.checkboxRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
}
