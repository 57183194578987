.recentStartInfo {
  grid-column-start: 1;
  grid-column-end: 7;
  background-color: var(--color-grey--100);
  padding: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
  border-radius: 0.4rem;

  &.shortFormBetType {
    grid-column-end: 5;
  }

  @media (--tablet-viewport) {
    grid-column-end: 3;
  }

  @media (--mobile-viewport) {
    padding: 0;
  }
}

.recentStartWatchReplay {
  flex-direction: row;
  align-items: center;
  border-radius: 0.4rem;
  margin-top: 1.2rem;
}

.label {
  font-weight: var(--font-weight--semi-bold);
}

.startInfo {
  display: grid;
}

.shortFormRunnerInfo {
  grid-column-start: 1;
  grid-column-end: 7;
  background-color: var(--color-grey--100);
  padding: 1.4rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.shortFormBetType {
    grid-column-end: 5;
  }

  @media (--tablet-viewport) {
    grid-column-end: 3;
  }
}
