.container {
  position: relative;
  overflow: hidden;
  height: auto;
}

.carousel {
  display: flex;
  flex-direction: row;
  height: 100%;
  transition: transform 0.25s ease-in-out;
}

.carouselItem {
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  position: relative;

  & > .backgroundImage {
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }

  & > .tint {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      250deg,
      var(--color-primary--200) 0%,
      var(--color-primary--400) 100%
    );
    background-blend-mode: hard-light;
    opacity: 0.3;
  }
}

.carouselContent {
  padding: 2.4rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & > .title {
    color: var(--white);
    font-size: var(--font-size--display-1);
    font-weight: var(--font-weight--bold);
    margin: 0;
    z-index: var(--z-index--badge);
  }
}

.quickContainer {
  margin: 0 auto;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.quickButton {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  border: none;
  background-color: var(--white);
  opacity: 0.3;
  margin: 0 0.8rem;
  padding: 0;
  transition: all 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    opacity: 1;
  }
}

.arrowButton {
  border-radius: 50%;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.8rem;
  padding: 0;
  color: var(--white);

  &:hover {
    cursor: pointer;
  }
}
