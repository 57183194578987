.hideMe {
  visibility: hidden;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  padding: 0.8rem;
}

.gap {
  gap: 4px;
}

.column {
  flex-direction: column;
  align-items: center;
  padding: 1.6rem 1.6rem 0.8rem;
}

.grid2:not(:last-child) {
  border-bottom: 0.1rem solid var(--color-grey--100);
  grid-column: 1 / 2;
  margin: 0 0.8rem;
}

.grid3:not(:last-child) {
  border-bottom: 0.1rem solid var(--color-grey--100);
  grid-column: 1 / 3;
  margin: 0 0.8rem;
}

.grid4:not(:last-child) {
  border-bottom: 0.1rem solid var(--color-grey--100);
  grid-column: 1 / 4;
  margin: 0 0.8rem;
}

.grid5:not(:last-child) {
  border-bottom: 0.1rem solid var(--color-grey--100);
  grid-column: 1 / 5;
  margin: 0 0.8rem;

  @media (--tablet-viewport) {
    grid-column: 1 / 3;
  }
}

.grid6:not(:last-child) {
  border-bottom: 0.1rem solid var(--color-grey--100);
  grid-column: 1 / 6;
  margin: 0 0.8rem;

  @media (--tablet-viewport) {
    grid-column: 1 / 3;
  }
}

.grid7:not(:last-child) {
  border-bottom: 0.1rem solid var(--color-grey--100);
  grid-column: 1 / 7;
  margin: 0 0.8rem;

  @media (--tablet-viewport) {
    grid-column: 1 / 3;
  }
}

.desktopOnly {
  @media (--tablet-viewport) {
    display: none;
  }
}

.shortFormTabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 -0.8rem 0;
  align-items: center;
  justify-content: center;
}

.shortFormTab {
  font-size: var(--font-size--body);
  text-decoration: none;
  color: var(--color-grey--600);
  font-weight: var(--font-weight--bold);
  padding: 0.8rem 0;
  position: relative;
  white-space: nowrap;
  margin: 0 0.8rem;

  &.selected::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.4rem;
    background-color: var(--color-secondary--200);
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }

  &.mobileOnly {
    display: none;

    @media (--tablet-viewport) {
      display: block;
    }
  }

  &.desktopOnly {
    display: block;

    @media (--tablet-viewport) {
      display: none;
    }
  }
}
