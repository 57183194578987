.visionPopUp {
  position: fixed;

  /* top offset given in div inline style */
  right: 1.6rem;
  background: black;
  border-radius: 0.8rem;
  z-index: var(--z-index--modal);

  & .popUpHeader {
    background: var(--color-secondary--200);
    padding: 8px;
    color: var(--white);
    font-weight: var(--font-weight--semi-bold);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
}

.closeIconContainer {
  margin: 0;
  background: none;
  border-radius: 4px;
  border: 1px solid var(--color-grey--50);
  color: var(--white);
  line-height: 0;

  &:hover {
    cursor: pointer;
    background: var(--color-grey--50);

    & .icon path {
      stroke: none;
      fill: var(--color-grey--600);
    }
  }

  &:active:focus {
    outline-style: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
  }
}

.flex {
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 8px;
}

.divMeetingSelect {
  height: 32px;
  background: var(--color-secondary--200);
  border: 1px solid var(--color-grey--50);
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.meetingSelect {
  font-weight: bold;
  height: 100%;
  color: var(--white);
  background: var(--color-secondary--200);
  border: none;
  margin: 0 8px;
  width: calc(100% - 16px);
  cursor: pointer;
  border-radius: 4px;

  &:focus {
    box-shadow: none !important;
  }
}

.divDragIcon {
  height: 32px;
  width: 32px;
  border: 1px solid var(--color-grey--50);
  border-radius: 4px;
  margin-right: 8px;
}

.dragIcon {
  display: inline-block;
  width: 20px;
  height: 10px;
  margin: 11px 5px;
}

.dragIcon,
.dragIcon::before {
  background-image: radial-gradient(var(--color-grey--50) 50%, transparent 40%);
  background-size: 5px 5px;
  background-position: 0 100%;
  background-repeat: repeat-x;
}

.dragIcon::before {
  content: '';
  display: block;
  width: 100%;
  height: 43%;
}
