.allPools {
  width: 100%;
  border-top: 0.1rem solid var(--color-grey--100);
  border-left: 0.1rem solid var(--color-grey--100);
  border-right: 0.1rem solid var(--color-grey--100);
}

.title {
  background-color: var(--color-secondary--200);
  color: var(--white);
}

.row {
  width: 100%;
  height: 4rem;
  padding: 0 2rem;
  border-bottom: 0.1rem solid var(--color-grey--100);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
