.hasError {
  & * {
    border-color: var(--color-error);
  }
}

.errorText {
  display: block;
  font-size: var(--font-size--caption);
  color: var(--color-error);
  margin-top: -1.1rem;
  margin-bottom: 1.1rem;
}
