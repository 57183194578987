:root {
  --black: var(--color-grey--800);
  --white: #fff;

  --color-grey--50: #eef0f2;
  --color-grey--100: #dbdee3;
  --color-grey--200: #b3b8c2;
  --color-grey--300: #979dab;
  --color-grey--400: #7b8294;
  --color-grey--500: #60697e;
  --color-grey--600: #465169;
  --color-grey--700: #2c3a54;
  --color-grey--800: #232d3f;

  --color-primary--50: #a8dc9c;
  --color-primary--100: #3eb738;
  --color-primary--200: #349a2f;
  --color-primary--300: #2a7c26;
  --color-primary--400: #2c6a26;

  --color-secondary--50: #c0d4d7;
  --color-secondary--100: #3e828c;
  --color-secondary--200: #346c75;
  --color-secondary--300: #29575d;
  --color-secondary--400: #154349;

  --color-success: #049b5e;
  --color-alert: #dd6b20;
  --color-error: #e2215a;

  --font-family: 'Source Sans Pro', sans-serif;

  --font-size--display-1: 2.2rem;
  --font-size--display-2: 2rem;

  --font-size--header-1: 1.6rem;
  --font-size--header-2: 1.4rem;
  --font-size--header-3: 1.2rem;

  --font-size--body: 1.4rem;

  --font-size--label: 1.2rem;

  --font-size--caption: 1.1rem;

  --line-height--small: 1.1;
  --line-height--medium: 1.25;
  --line-height--large: 1.4;
  --line-height--title: 1.5;

  --font-weight--bold: 700;
  --font-weight--semi-bold: 600;
  --font-weight--regular: 400;
  --font-weight--light: 300;

  --z-index--badge: 301;
  --z-index--layout: 10;
  --z-index--priority: 100;
  --z-index--modal: 300;
}
