.flag {
  display: flex;
  margin-right: 0.6rem;
}

.small {
  width: 1.6rem;
  height: 1.6rem;
}

.medium {
  width: 2.4rem;
  height: 2.4rem;
}

.large {
  width: 3.2rem;
  height: 3.2rem;
}
