.cardContent {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 0.8rem 0 0;
}

.description {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.heading {
  font-weight: var(--font-weight--semi-bold);
  display: flex;
  justify-content: space-between;
  & div {
    display: flex;
    justify-content: space-between;
  }
}

.silk {
  /* margin-top: 1.8rem; */
}

.card {
  padding: 0.8rem;
  margin: 0.8rem 0;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0  0  0 3.2rem;
  margin-top: -1.4rem;
}

.cardIcon {
  margin-right: 0.8rem;
}

.highlight {
  color: var(--color-success);
  margin-right: 0.8rem;
}

.transactionValues {
  width: 15rem;
  display: flex;
  justify-content: space-between;
}

.betDetail {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
}
.button {
  border: 1px solid var(--color-grey--100);
  margin: 0.4rem 0.4rem 0 0;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  font-weight: var(--font-weight--normal);

  &.selected {
    background-color: var(--color-secondary--200);
    color: var(--white);
  }
}


.DEPOSIT {
  color: var(--color-success);
}

.WITHDRAWAL {
  color: var(--color-error);
}

.actionButton {
  margin-left: 0.8rem;
}
.bottomContent {
  display: none;
  padding: 0.8rem;
  margin: 0.8rem;
  background-color: var(--color-grey--50);

  & div {
    margin-right: 1.6rem;
    padding: 0.4rem 2.4rem;
    display: flex;
    flex-direction: column;
  }
}

.expanded {
  & .chevron {
    transform: rotate(180deg);
  }

  & .bottomContent {
    display: flex;
    flex-wrap: wrap;
  }
}

.bold {
  font-weight: var(--font-weight--semi-bold);
  width: 10rem;
}

.win {
  color: var(--color-success);
  margin-left: 0.8rem;
}