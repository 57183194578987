.container {
  background-color: var(--color-primary--200);
  padding: 2.4rem 4rem 4rem;
  border-radius: 0.4rem;
  overflow: auto;

  @media (--mobile-viewport) {
    padding: 2.4rem 1.6rem;
    border-radius: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.header {
  text-align: center;
}

.title {
  display: block;
  font-weight: var(--font-weight--bold);
  color: var(--white);
}

.form {
  padding: 4rem;
  background-color: var(--white);
  border-radius: 0.8rem;
  margin-top: 1.6rem;
  width: 100%;
  position: relative;

  &.hasError {
    padding-top: 6.4rem;
  }
  
  @media (--mobile-viewport) {
    padding: 2.4rem;
  }
}

.secondary {
  color: var(--color-grey--500);
  text-align: center;
  margin: 1.6rem 0 0;
}

.center {
  text-align: center;
}

.note {
  color: var(--color-grey--500);
}

.marginBottom {
  margin-bottom: 3.2rem;
}

.fullHeight {
  @media (--mobile-viewport) {
    height: 100%;
  }
}

.closeButton {
  color: var(--white);
}

.errorMessage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--color-error);
  color: var(--white);
  padding: 0.8rem;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: flex;
  align-items: center;
}
