.background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: var(--z-index--layout);
}

.mainNavigationContainer {
  width: 24rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--white);
  overflow-y: auto;

  &.open {
    display: flex;
  }

  @media (--tablet-viewport) {
    display: none;
  }
}

.logoContainer {
  padding: 1.6rem;
}

.logoLink {
  display: block;
  line-height: 0;

  & > .logo {
    width: 100%;
  }
}

.navigation {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}

.navList {
  margin: 0;
  padding: 0;
  user-select: none;
}

.navListItem {
  list-style: none;
}

button.navItem {
  width: 100%;
  text-align: left;
  background: inherit;
  border: none;
}

.navItem {
  display: grid;
  grid-template-columns: 4rem 1fr auto;
  align-items: center;
  position: relative;
  padding: 0 1.6rem;
  height: 4.8rem;
  text-decoration: none;
  color: var(--black);
  font-weight: var(--font-weight--bold);

  &.selected::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.4rem;
    background: var(--color-primary--200);
  }

  & > .icon {
    margin-right: 1.6rem;
  }

  & > .navItemLabel {
    flex: 1;
    font-size: var(--font-size--body);
  }

  &:focus {
    box-shadow: 0 0 1px 2px rgb(0, 0, 0, 0.02) !important;
  }
}

.childNavItem {
  display: grid;
  grid-template-columns: 4rem 1fr auto;
  align-items: center;
  padding: 0 1.6rem;
  height: 4.8rem;
  text-decoration: none;
  color: var(--black);

  &.selected {
    background-color: var(--color-grey--100);
  }

  & > .navItemLabel {
    font-size: var(--font-size--body);
  }
}

.chevronButton {
  background: none;
  border: none;
  display: flex;

  &:hover {
    cursor: pointer;
  }

  &.expanded {
    transform: rotateX(180deg);
  }
}

.divOddsSwitcher {
  width: 100%;
  background-color: var(--white);
  padding: 1.6rem;
}

.oddsSwitcher {
  width: 100%;
  padding: 0.4rem;
  border-radius: 0.4rem;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.08);

  &:hover {
    cursor: pointer;
  }
}

.mobileNavigationContainer {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;

  @media (--tablet-viewport) {
    display: block;
  }
}

.mobileNavigation {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.08);

  & > * {
    flex: 1;
  }
}

.mobileNavButton {
  background-color: var(--white);
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0;
  color: var(--color-grey--400);

  & > .icon {
    margin-bottom: 0.8rem;
  }

  & > .label {
    font-weight: var(--font-weight--bold);
  }

  &.selected {
    color: var(--color-grey--600);
  }

  &:hover {
    cursor: pointer;
  }
}

.hamburger {
  max-width: 2.4rem;
  max-height: 2.4rem;
  padding: 0.6rem;
}
