.container {
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  overflow: hidden;
  border: 1px solid var(--color-grey--100);
  align-self: flex-start;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  height: 3.6rem;
}

.button {
  margin: 0 0.8rem 0.8rem 0;

  &.selected {
    background-color: var(--color-secondary--200);
    color: var(--white);
  }
}

.smallButton {
  padding: 0 0.8rem;
}
