.container {
  font-family: var(--font-family);
  font-size: var(--font-size--body);
  line-height: var(--line-height--medium);
  box-shadow: 0 6px 12px 1px rgba(0, 0, 0, 0.12);
  width: 36rem;
  background-color: var(--white);
  margin-right: 1.2rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
}

.greeting {
  flex: 1;
  font-size: var(--font-size--header-1);
  font-weight: var(--font-weight--bold);
}

.balance {
  font-weight: var(--font-weight--semi-bold);
}

.balanceContainer {
  background-color: var(--color-grey--50);
  padding: 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.grid {
  flex: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.6rem;
  margin-right: 0.8rem;

  & .label {
    font-weight: var(--font-weight--semi-bold);
    display: flex;
    align-items: center;
    color: var(--color-grey--600);
  }
  
  & .value {
    font-weight: var(--font-weight--semi-bold);
    display: flex;
    align-items: center;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.footer {
  & > *:not(:last-child) {
    border-bottom: 0.1rem solid var(--color-grey--100);
  }
}

.button {
  width: 100%;
  padding: 1.6rem;
  justify-content: flex-start;
  font-family: var(--font-family);
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: inherit;
  width: 100%;
  padding: 1.6rem;
  gap: 0.6rem;
  font-size: var(--font-size--body);
  font-weight: var(--font-weight--semi-bold);
}

.buttonContent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
