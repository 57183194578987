.container {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  margin-bottom: 0.8rem;
}

.button {
  flex: 1;
}
