.container {
  width: 32px;
  height: 32px;
  display: inline-block;
}

.silkContainer {
  position: relative;
  width: 64px;
  height: 64px; /* Intentional as spritesheets are 32x32 and are not relative */
  overflow: hidden;
  flex-shrink: 0;
  display: block;
  transform: scale(0.5);
  transform-origin: top left;
}

.silkSpritesheet {
  position: absolute;
}

.greyhoundSilkSpritesheet {
  position: absolute;
  width: 100%;
}
