.button {
  padding: 0;
  line-height: 0;
  border: none;
  background: none;
  z-index: var(--z-index--badge);

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.tooltip {
  background: var(--color-secondary--200);
  color: var(--white);
  font-family: var(--font-family);
  font-size: var(--font-size--label);
  border-radius: 0.4rem;
  z-index: var(--z-index--badge);
  text-align: center;
  max-width: 18rem;
  padding: 0.8rem;
  line-height: 1.6rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.arrow,
.arrow::before {
  position: absolute;
  left: 0;
  width: 0.8rem;
  height: 0.8rem;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.tooltip[data-popper-placement^='top'] > .arrow {
  bottom: -0.4rem;
}
.tooltip[data-popper-placement^='bottom'] > .arrow {
  top: -0.4rem;
}
.tooltip[data-popper-placement^='left'] > .arrow {
  right: -0.4rem;
}
.tooltip[data-popper-placement^='right'] > .arrow {
  left: -0.4rem;
}

.badgeTitle {
  font-size: var(--font-size--label);
  font-weight: var(--font-weight--bold);
  text-align: center;
}

.details {
  display: block;
  margin: 0.4rem 0;
}

.disclaimer {
  font-style: italic;
}

.helper {
  display: flex;
  align-items: center;
}

.helperText {
  color: var(--color-grey--600);
  font-size: var(--font-size--caption);
  margin-left: 0.4rem;
}
