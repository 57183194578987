.betitem-animate-in {
  opacity: 0;
  max-height: 0;
  pointer-events: none;
  transition: all 100ms ease-in-out;
}
.betitem-animate-in-done {
  opacity: 1;
  max-height: 100rem;
  transition: all 100ms ease-in-out;
}

.betitem-animate-out {
  max-height: 0;
  pointer-events: none;
}

.betitem-hide {
  max-height: 0;
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.accordionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-grey--100);
  padding: 0.8rem;
}

.accordionButton {
  background-color: transparent;
  color: var(--color-grey--600);
  border: none;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: var(--font-weight--bold);

  &:hover {
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0 0 1px 2px rgb(0, 0, 0, 0.02) !important;
  }
}

.chevron {
  margin-right: 0.8rem;
}

.bets {
  display: none;
  margin: 0.8rem;
}

.expanded {
  & .chevron {
    transform: rotate(180deg);
  }

  & .bets {
    display: block;
  }
}

.stakeInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  display: block;
  color: var(--color-grey--600);
  font-size: var(--font-size--label);
  margin-right: 0.8rem;
}
