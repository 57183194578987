.container {
  padding: 2.4rem 1.6rem;
}

.header {
  margin-bottom: 1.6rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
}

.NextToJumpFilter {
  display: flex;
}

.filters {
  display: flex;
  flex-flow: row wrap;
  gap: 0.8rem;
}
