.container {
  padding: 2.4rem;
}

.heading {
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--display-2);
  text-align: center;
  margin-bottom: 1.6rem;
}

.input {
  height: 4rem;
}

.subText {
  text-align: center;
  margin-bottom: 2.4rem;
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.inputContainer {
  margin-top: 2.4rem;
  display: flex;
  gap: 0.8rem;
}

.halfWidth {
  min-width: 50%;
}
