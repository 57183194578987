.container {
  padding: 1.6rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
}

.filters {
  display: flex;
  flex-flow: row wrap;
  gap: 0.8rem;
}

.title {
  color: var(--color-grey--600);
}
