.container {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index--modal);

  @media (--mobile-viewport) {
    align-items: flex-end;
  }
}

.modal {
  position: relative;
  width: 48rem;

  @media (--mobile-viewport) {
    width: 100%;
  }
}

.closeButton {
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  border: none;
  background: none;
  padding: 0;
  line-height: 0;

  &:hover {
    cursor: pointer;
  }
}

.fullscreen {
  width: 100%;
  height: 100%;

  & > .closeButton {
    top: 2.4rem;
    right: 2.4rem;
  }
}
