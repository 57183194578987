.container {
  color: white;
  box-shadow: none;
  margin: 0.8rem 0;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chevronPrev {
  margin-right: 0.8rem;
  transform: rotate(90deg);
}

.chevronNxt {
  margin-left: 0.8rem;
  transform: rotate(270deg);
}

.raceButtonGroup {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 0.8rem;
  margin: 0 0.8rem;

  @media (--tablet-viewport) {
    display: none;
  }
}

.bounds {
  flex: 1;

  &.next {
    text-align: right;
  }
}

.buttonPrev {
  padding: 0.8rem 1.6rem 0.8rem 1rem;
}

.buttonNext {
  padding: 0.8rem 1rem 0.8rem 1.6rem;
}
