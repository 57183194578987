.container {
  padding: 1.6rem 1.6rem 0;
  margin-bottom: 0.8rem;
}

.singleCard {
  margin-bottom: 0;
  border-radius: 0.4rem 0.4rem 0 0;
}

.form {
  margin-top: 0.8rem;
  display: flex;
  gap: 0.8rem;
  margin-bottom: 0;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  gap: 0.8rem;
  margin-top: 1.6rem;
}

.existingCard {
  display: flex;
  background: var(--white);
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
}

.select {
  padding-left: 1.6rem;
  background-color: var(--color-grey--50);
  height: 4rem;
  border: 0 !important;
}

.input {
  height: 4rem;
}

.button {
  display: flex;
  background: var(--color-grey--50);
  width: 4rem;
  align-items: center;
  align-self: center;
  margin-top: 0.2rem;
  margin-left: 0.8rem;
}

.addCardButton {
  height: 4rem;
  align-self: center;
  margin-top: 0.2rem;
  margin-left: 0.8rem;
  white-space: pre;
  border-color: var(--color-grey--200);
  color: var(--black);
}

.halfWidth {
  min-width: 50%;
}

.cardImage {
  margin-bottom: -0.4rem;
}
