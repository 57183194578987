.button {
  font-size: var(--font-size--body);
  border-radius: 0.4rem;
  border: 0.1rem solid;
  transition: all 0.2s ease-in-out;
  padding: 0.8rem 1.6rem;
  font-weight: var(--font-weight--semi-bold);
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.75;
    border: 1px solid;
  }

  &:not(:disabled):hover {
    cursor: pointer;
  }
}

.theme--default {
  background-color: var(--white);
  border-color: var(--white);

  &:not(:disabled):hover,
  &[data-selected='true'] {
    background-color: var(--color-grey--600);
    border-color: var(--color-grey--600);
    color: var(--white);
  }
}

.theme--primary {
  background-color: var(--color-primary--400);
  border-color: var(--color-primary--400);
  color: var(--white);

  &:not(:disabled):hover,
  &[data-selected='true'] {
    background-color: var(--white);
    color: var(--color-primary--400);
  }
}

.theme--secondary {
  background-color: var(--color-secondary--200);
  border-color: var(--color-secondary--200);
  color: var(--white);

  &:not(:disabled):hover,
  &[data-selected='true'] {
    background-color: var(--white);
    color: var(--color-secondary--200);
  }
}

.theme--transparentDark {
  background-color: transparent;
  border-color: var(--color-grey--600);

  &:not(:disabled):hover,
  &[data-selected='true'] {
    background-color: var(--color-grey--600);
    border-color: var(--color-grey--600);
    color: var(--white);
  }
}

.theme--transparentLight {
  color: var(--white);
  border-color: var(--white);
  background-color: transparent;
  font-size: var(--font-size--body);
  border-radius: 0.4rem;
  border: 0.1rem solid;
  padding: 0 1.6em;

  &:not(:disabled):hover,
  &[data-selected='true'] {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--color-secondary--200);
  }
}

.theme--none {
  background: none;
  border: none;
  padding: 0;
}

.size--default {
  height: auto;
}

.size--small {
  min-height: 2.4rem;
  padding: 0.2rem 0.8rem;
}

.size--medium {
  min-height: 3.2rem;
}

.size--large {
  min-height: 4.3rem;
  padding: 1.2rem 2.4rem;
}

.buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  display: flex;
}

.marginRight {
  margin-right: 0.6rem;
}

.marginLeft {
  margin-left: 0.6rem;
}
