.input {
  border-radius: 0.4rem;
  background-color: var(--color-grey--50);
  border: none;
  padding: 0.4rem 0.8rem;

  &:focus {
    background-color: var(--white);
    box-shadow: none !important;
  }
}

.inputPrefix {
  display: flex;
  align-items: center;
  max-width: 30rem;
  background: #fff;
  border: 0.1rem solid #a0a0a0;
  border-radius: 0.4rem;
  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}

.inputPrefix .currencyCode {
  font-weight: 300;
  font-size: 1.4rem;
  color: black;
}

.inputPrefix input {
  flex-grow: 1;
  font-size: 1.4rem;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.3rem;
}

.inputPrefix:focus-within {
  border-color: #777;
}
