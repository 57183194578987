.select {
  appearance: none;
  background-image: url('/src/components/Icon/icons/caretDown.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 1.2rem);
  background-size: 2rem;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--color--grey-dark);
  padding: 0.8rem;
  padding-left: 0.5rem;
}
