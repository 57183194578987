.resultContainer {
  margin: 0.8rem 1.6rem;
  padding: 0 1.6rem;
  background: var(--white);
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.0812937);
  border-radius: 4px;
}

.resultHeader {
  margin: 1.6rem 0 0 0;
  font-weight: var(--font-weight--bold);
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-grey--400);
}

.resultBookie {
  margin: 1.6rem 3rem 0 0;
  font-weight: var(--font-weight--bold);
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-grey--400);
}

.headerContainer {
  margin: 1rem 1.6rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 0 0 100%;
}

.replayHeader {
  margin-left: 0.8rem;
}

.resultSeparator {
  height: 0.1rem;
  background: var(--color-grey--100);
}

.runnerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0;

  @media (--tablet-viewport) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.resultsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--tablet-viewport) {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
}

.resultsInfo {
  margin: 0 1.6rem;

  @media (--tablet-viewport) {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0.8rem;
    width: 100%;

    &:not(:first-child) {
      border-top: 0.1rem solid var(--color-grey--50);
    }
  }
}

.exoticsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.multiplesContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.runnerInfo {
  margin: 0.8rem;
}

.exoticsInfo {
  margin: 0 1.6rem;
}

.exoticsName {
  font-weight: var(--font-weight--bold);
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #23262b;
}

.exoticsPrice {
  font-size: 1.4rem;
  color: #23262b;
}

.jockeyName {
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: #525864;
}

.exoticsResult {
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: #525864;
}
