.cardContent {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 0;
}

.description {
  display: flex;
  flex-direction: column;
}

.heading {
  font-weight: var(--font-weight--semi-bold);
  margin-bottom: 1.6rem;
}

.card {
  padding: 0 1.6rem;
  margin: 1rem 0;
}