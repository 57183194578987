.container {
  margin-top: 4rem;
}

.wrapper {
  border-radius: 0.4rem;
  overflow: hidden;
  flex-shrink: 0;
}

.competitor {
  padding: 1.6rem 1.6rem 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  margin-bottom: 0.2rem;
}

.odds {
  display: block;
  margin-top: 0.4rem;
}

.empty {
  background-color: var(--white);
}

.separator {
  height: 0.2rem;
  background-color: var(--color-grey--50);
}
