.container {
  margin-top: 2.4rem;
  display: flex;
  justify-content: center;
  color: var(--color-grey--600);
  font-size: var(--font-size--caption);
}

.stepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 3.8rem;
  min-width: 5rem;
}

.icon {
  width: 1.6rem;
  height: 1.6rem;
  color: var(--color-success);
  font-weight: bold;
}
