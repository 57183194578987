.depositContainer {
  border-radius: 0.4rem 0 0 0.4rem;
  display: flex;
  max-width: 100rem;

  @media (max-width: 720px) {
    flex-direction: column;
  }
}

.depositMenu {
  padding: 1.6rem;
  background: var(--white);
  border-radius: 0.4rem 0 0 0.4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  min-width: 20rem;
  width: 36%;

  @media (max-width: 720px) {
    flex-direction: column;
    width: 100%;
    border-radius: 0.4rem 0.4rem 0 0;
  }
}

.contentSection {
  background: var(--color-grey--100);
  font-size: var(--font-size--label);
  width: 100%;
  padding: 1.6rem;
}

.cardPadding {
  padding: 1.6rem;
}

.bottomCardMargin {
  margin-top: 0.8rem;
}

.infoBoxContainer {
  padding: 1.6rem;
  padding-bottom: 0;
  overflow: hidden;
  margin-bottom: 0.8rem;
}
