.container {
  pointer-events: unset;

  &.locked {
    pointer-events: none;
  }
  &.locked::after {
    content: '';
    position: absolute;
    inset: 0;
    background: #eee9;
    border-radius: 4px;
  }
}
