.countdown {
  font-weight: var(--fontWeightNormal);

  &.warning {
    color: var(--color-alert);
  }

  &.urgent {
    color: var(--color-error);
  }

  &.posted {
    color: var(--color-grey--600);
  }
}
