.container {
  border-radius: 0.4rem;
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 1.2rem 1.6rem;
  flex: 1;
  min-width: 28rem;
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;

  &:not(:last-child) {
    margin-right: 1.6rem;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.center {
    justify-self: center;
  }
}

.icon {
  align-self: flex-start;
}

.raceDetails {
  flex: 1;
  margin: 0 0.8rem;
  overflow: hidden;
}

.raceName {
  display: block;
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--body);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  color: var(--black);
  line-height: var(--line-height--medium);
}

.pool {
  display: block;
  font-size: var(--font-size--label);
  color: var(--color-grey--600);
  line-height: var(--line-height--medium);
}

.runners {
  padding: 0.8rem 0;
  flex: 1;
}

.grid {
  display: grid;
  grid-template-columns: 1fr auto;

  & > .header {
    font-size: var(--font-size--caption);
    color: var(--color-grey--600);
  }

  & > .separator {
    border-bottom: 0.1rem solid var(--color-grey--100);
    grid-column: 1 / 3;
    margin: 0.4rem 0;
  }
}

.runner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4rem 0;
  overflow: hidden;
}

.runnerDetail {
  display: block;
  margin: 0 0.4rem;
  overflow: hidden;

  & > .runnerName {
    display: block;
    font-size: var(--font-size--label);
    font-weight: var(--font-weight--semi-bold);
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: var(--line-height--medium);
  }

  & > .jockey {
    display: block;
    font-size: 1.1rem;
    color: var(--color-grey--400);
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: var(--line-height--medium);
  }
}

.oddsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer {
  text-align: center;
}

.viewRaceCard {
  display: block;
  font-size: var(--font-size--body);
  font-weight: var(--font-weight--semi-bold);
  text-decoration: none;
  color: #3e818c;
}

.countdown {
  font-size: var(--font-size--label);
}
