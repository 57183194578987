.container {
  padding-bottom: 1.6rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1.6rem;
  background-color: var(--color-grey--50);
}

.filters {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 0.8rem;
}

.dateInput {
  background-color: var(--white);
}

.trackFilter {
  padding: 0.4rem;
  border-radius: 0.4rem;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--color-grey--100);

  &:hover {
    cursor: pointer;
  }
}

.raceHeader {
  margin: 0 1.6rem;
  padding: 1.6rem;
  background-color: var(--white);
  border-radius: 0.4rem;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.08);
}
