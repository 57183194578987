.grid {
  flex: 1;
  border-radius: 0.4rem;
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  margin: 4rem 0 0.8rem;
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  gap: 1.6rem 3.2rem;
  padding-bottom: 1.6rem;
}

.sortLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-grey--600);
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--label);
  position: relative;
  top: -1.6rem;
  height: 0;
  user-select: none;
  gap: 0.4rem;

  &.right {
    justify-content: flex-end;
    text-align: right;
  }

  &.spacing {
    padding: 0 1.6rem;
  }
}

.link {
  text-decoration: none;
  color: inherit;
}

.separator {
  grid-column: 1 / 5;
  height: 0.1rem;
  background-color: var(--color-grey--100);
  margin: 0 1.6rem;

  &:last-child {
    display: none;
  }
}

.raceDetailContainer {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  margin-left: 1.6rem;
}

.raceDetail {
  display: flex;
  gap: 0.8rem;
  margin-bottom: 0.2rem;
}

.raceName {
  font-weight: var(--font-weight--bold);
}

.raceNumber {
  color: var(--color-grey--400);
}

.country {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-grey--400);
  font-size: var(--font-size--caption);
}

.racePool {
  text-align: right;
}

.pool {
  color: var(--color-grey--400);
}

.legend {
  display: flex;
  gap: 0.4rem;
}

.countdown {
  text-align: right;
  font-weight: var(--font-weight--bold);
  margin-right: 1.6rem;
}

.empty {
  padding: 0 1.6rem;
}
